<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Header -->
    <Header />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged @reload="reloadPage" />
    <!--End Footer -->

    <!--Job Listing -->
    <div class="page-content mt-5">
      <div class="card bg-banner" data-card-height="250">
        <div class="card-top text-center">
          <div class="content">
            <div class="search-box search-dark shadow-xl bg-theme mt-1">
              <i class="fa fa-search"></i>
              <input
                type="text"
                class="border-0"
                placeholder="Cari Pekerjaan"
                v-model="dataSearch"
                @keyup.enter="getData"
              />
            </div>
          </div>
        </div>
      </div>
      <FilterButton @openFilter="menuOpen" />
      <FilterList
        :categories="categories"
        @filterList="addFilterData"
        @close="menuClose"
      />
      <ListJob v-for="job in listJob" :key="job.id" :job="job" />
      <EmptyState
        v-if="listJob.length === 0"
        :message1="'Maaf saat ini belum ada pekerjaan yang tersedia'"
        :cardCenter="false"
      />
    </div>
    <!--End Job Listing -->
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import ListJob from "@/components/list_job/ListJob";
import FilterButton from "@/components/job/FilterButton";
import FilterList from "@/components/job/FilterList";

import EmptyState from "@/components/EmptyState";
export default {
  name: "CategoryJobList",
  components: {
    Header,
    Footer,
    ListJob,
    FilterButton,
    FilterList,
    EmptyState,
  },
  data() {
    return {
      dataSearch: "",
      listJob: [],
      isAuthenticated: this.$store.state.isAuthenticated,
      isLoading: true,
      params: {},
      dataFilterList: [],
      categories: [],
    };
  },
  mounted() {
    init_template();
    this.params = this.$route.params;
    this.getData();
    this.getCategory();
  },
  methods: {
    menuClose,
    menuOpen,
    addFilterData(data) {
      this.dataFilterList = data;
      this.getData();
    },
    async getCategory() {
      var apiUrl = "/api/v1/employer/category/?category=" + this.params.parent;
      await axios
        .get(apiUrl)
        .then((response) => {
          console.log("response categories :", response.data);
          this.categories = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getData() {
      var url = "";
      const params = this.params;

      if (params.level === "L1") {
        url = "/api/v1/job-seeker/job-list/category/?level=1&parent=";
        url += params.parent;
      }

      if (params.level === "L2") {
        url = "/api/v1/job-seeker/job-list/category/?level=2&parent=";
        url += params.parent;
      }

      /* filter /search */
      if (this.dataSearch) {
        url += "&search=" + this.dataSearch;
      }

      /*filter list*/
      if (this.dataFilterList) {
        console.log("filter list : ", this.dataFilterList);
        var data = this.dataFilterList;
        const paramsFilter = [];
        for (const key in data) {
          data[key].forEach((item) => {
            paramsFilter.push(item.value);
          });
          url += "&" + key + "=" + paramsFilter.join("|");
        }
        console.log(url);
      }

      await axios
        .get(url)
        .then((response) => {
          var responseData = response.data;
          if ("results" in responseData) {
            this.listJob = responseData.results;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.bg-banner {
  background-image: url("~@/assets/images/banner/banner.svg");
}
</style>
